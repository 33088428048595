import React from 'react';  
  
const TaskList = ({ tasks }) => {  
  return (  
    <div className="max-h-80">  
      {tasks.map((task, index) => (  
        <div key={index} className="flex items-start py-4 border-b">  
          <div className="w-3/4 pr-4">  
            <div className="flex items-center font-bold">  
              <span className="mr-2">  
                {/* Replace this with your desired icon */}  
                <img height="32" width="32" src="https://cdn.jsdelivr.net/npm/simple-icons@v12/icons/discord.svg" />

              </span>  
              <span>{task.name}</span>  
            </div>  
            <p className="mt-1">{task.description}</p>  
          </div>  
          <div className="w-1/4 flex items-center justify-end">  
            <span className="font-bold">{task.points} Hue Points</span>  
          </div>  
        </div>  
      ))}  
    </div>  
  );  
};  
  
export default TaskList;