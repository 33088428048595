import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers"
import { useWalletCustom } from "../contexts/WalletContext"
import { connectWallet } from "../contexts/walletService"
import { metaMask } from "../App"
import Web3 from "web3";
import { ethers } from "ethers"
import { formatAddress, formatBalance } from './utils'


const ConnectButton = () => {
  const { isConnected, isValidChain, connect, switchNetwork, balance } = useWalletCustom();
  const [loading, setLoading] = useState(false)
  const { activate, account, library, chainId, active } = useWeb3React()

  const handleClick = async () => {  
    setLoading(true);  
    try {  
      if (!isConnected) {  
        await connectWallet(metaMask); 
        if(!isValidChain) {
          await switchNetwork(324);
        } 
      }  
    } catch (error) {  
      console.error('Error connecting or switching network:', error);  
    }  
    setLoading(false);  
  }; 

  useEffect(() => {  
    if (isConnected && chainId !== 324) {  
      switchNetwork(324);  
    }  
  }, [isConnected, chainId, switchNetwork]);

  const handleConnect = async () => {
    await connectWallet(metaMask);
  };

  console.log('account: ' + account)
  console.log('library: ' + library)
  console.log('chainId:', chainId);
  console.log('active:', active);


  return (
     <>
      {isConnected ? (
        <>
         <div className="flex items-center bg-gray-100 px-4 py-1 rounded-full">
       <div className="flex items-center">
         <i className="bx bx-wallet mr-2" /> {balance}
       </div>
       <div
         className="flex items-center bg-blue-500 text-white px-2 py-1 ml-4 rounded-full border border-blue-500 cursor-pointer hover:bg-gray-100 hover:text-gray-600 hover:border-blue-600"
          
       >
         {formatAddress(account)}
       </div>
     </div>
        </>
      ) : (   
        <button
          type="button"
          className={`ml-3 inline-flex items-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 ${loading ? "loading" : ""}`}
          disabled={!!loading}
          onClick={handleClick}
        >
          
              Connect Wallet
           
        </button>
      )}
    </>
  );


}

export default ConnectButton
