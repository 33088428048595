import React, { createContext, useContext, useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { connectWallet, switchChain, getBalance } from './walletService';
import { formatBalance } from '../Components/utils'

const WalletContext = createContext(null);

export const useWalletCustom = () => useContext(WalletContext);
const VALID_CHAINS = [324]; // Example valid chain IDs

const CHAIN_LOGOS = {
  324: 'https://example.com/eth-logo.png',
};
const CHAIN_CURRENCIES = {
  324: 'ETH',
};
const CONTRACT_ADDRESSES = {
  324: '0xCC9124315B30da02c1d933A4452D194Cc73885E1'
}


export const WalletProvider = ({ children }) => {
  const { activate, account, provider, chainId, active } = useWeb3React();
  const [balance, setBalance] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isValidChain, setIsValidChain] = useState(false);
  const [isRightChain, setIsRightChain] = useState(false);
  const [targetChainId, setTargetChainId] = useState(null);
  const [currentChainId, setCurrentChainId] = useState(null)

  useEffect(() => {
    const fetchBalance = async () => {
      if(provider) {
        const balance = await provider.getBalance(account);
      console.log('balance: ' + balance)
      setBalance(balance);
      }
      
    };

    fetchBalance();
  }, [account, provider]);

  useEffect(() => {
    setIsConnected(!!account);
    setIsValidChain(VALID_CHAINS.includes(chainId));
    setCurrentChainId(chainId)
    if(chainId !== 324) {
          switchNetwork(324);
        } 
  }, [account, chainId, targetChainId]);

   const connect = async () => {  
    try {  
      await connectWallet(activate);  
      if (!isValidChain) {  
        await switchNetwork(324);  
      }  
    } catch (error) {  
      console.error('Failed to connect:', error);  
    }  
  };  
  
  const switchNetwork = async (chainId) => {  
    try {  
      await switchChain(provider, chainId);  
      setTargetChainId(chainId);  
    } catch (error) {  
      console.error('Failed to switch network:', error);  
    }  
  };

  const getChainLogo = () => CHAIN_LOGOS[chainId];
  const getChainCurrency = () => CHAIN_CURRENCIES[chainId];
  const getContractAddress = () => CONTRACT_ADDRESSES[chainId];


  return (
    <WalletContext.Provider value={{ balance: formatBalance(balance), isConnected, currentChainId, isValidChain, chainId, connect, switchNetwork, getChainLogo, getContractAddress }}>
      {children}
    </WalletContext.Provider>
  );
};