import { NavLink } from 'react-router-dom'

const navigation = {
  main: [
    { name: 'Home', href: '/' },
    // { name: 'Leaderboard', href: '/leaderboard' },
    // { name: 'Profile', href: '/profile' },
    { name: 'Swag Shop', href: '/shop' },
    { name: 'Purchased', href: '/purchased' },
  ],
 social: [
    {
      name: 'X',
      href: 'https://x.com/hue_nfts',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    }]
}

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <NavLink to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                {item.name}
              </NavLink>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {navigation.social.map((item) => (
            <NavLink key={item.name} to={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </NavLink>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2024 PopFi powered by Hue & idexo All rights reserved.
        </p>
      </div>
    </footer>
  )
}
