import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { PlusCircleIcon, ClipboardDocumentListIcon, ChartPieIcon, CogIcon } from '@heroicons/react/20/solid' 

const tabs = [
  { name: 'Add Swag', href: '/admin/AdminForm', icon: PlusCircleIcon },
  { name: 'Add Tasks', href: '/admin/TaskForm', icon: ClipboardDocumentListIcon },
  { name: 'Manage Tasks', href: '/admin/AdminTaskList', icon: CogIcon },
  { name: 'View Analytics', href: '/admin/SummaryAnalytics', icon: ChartPieIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AdminHeader() {
  const [selectedNavLink, setSelectedNavLink] = useState(tabs[0].href) 
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={selectedNavLink}  
          onChange={(e) => setSelectedNavLink(e.target.value)} 
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 px-4 py-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={classNames(  
                  selectedNavLink === tab.href  
                    ? 'border-indigo-500 text-indigo-600'  
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',  
                  'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'  
                )}  
                onClick={() => setSelectedNavLink(tab.href)}  
              >
               <tab.icon  
                  className={classNames(  
                    selectedNavLink === tab.href ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',  
                    '-ml-0.5 mr-2 h-5 w-5'  
                  )}  
                  aria-hidden="true"  
                />  
                <span>{tab.name}</span>  
              </NavLink>  
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
