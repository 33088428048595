import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletProvider } from './contexts/WalletContext'
import Layout from "./Layout/Layout"
import ShopTwo from "./Pages/ShopTwo"
import HuePortal from "./Pages/HuePortal"
import YourNFTs from "./Pages/YourNFTs"
import RedeemDelivery from "./Pages/RedeemDelivery"
import AdminForm from "./Pages/Admin/AdminForm"
import LeaderBoard from "./Pages/LeaderBoard"
import AvailableTasks from "./Pages/AvailableTasks"
import CompletedTasks from "./Pages/CompletedTasks"
import Points from "./Pages/Points"
import Tasks from "./Pages/Tasks"
import AdminLayout from "./Layout/AdminLayout"
import AdminTaskList from "./Pages/Admin/AdminTaskList"
import TaskForm from "./Pages/Admin/TaskForm"
import SummaryAnalytics from "./Pages/Admin/SummaryAnalytics"
import { initializeConnector } from '@web3-react/core'
import { AffiliateProvider } from './contexts/AffiliateContext'
import { MetaMask } from '@web3-react/metamask'
import { DeliveryProvider } from './contexts/DeliveryContext'; 


const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

export const [metaMask, hooks] = initializeConnector((actions) => new MetaMask({ actions }));
const connectors = [[metaMask, hooks]]

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary} connectors={connectors}>
    <WalletProvider>
    <AffiliateProvider>
    <DeliveryProvider>
    <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
      <Route index element={<ShopTwo />} />
      <Route path="/leaderboard" element={<LeaderBoard />} />
      <Route path="/tasks" element={<HuePortal />} >
        <Route path="/tasks/AvailableTasks" element={<AvailableTasks />} />
        <Route path="/tasks/CompletedTasks" element={<CompletedTasks />} />
      </Route>
      <Route path="/admin" element={<AdminLayout />} >
        <Route path="/admin/AdminForm" element={<AdminForm />} />
        <Route path="/admin/TaskForm" element={<TaskForm />} />
        <Route path="/admin/AdminTaskList" element={<AdminTaskList />} />
        <Route path="/admin/SummaryAnalytics" element={<SummaryAnalytics />} />
      </Route>
      <Route path="/adminform" element={<AdminForm />} />
      <Route path="/points" element={<Points />} />
      <Route path="/hueportal" element={<HuePortal />} />
      <Route path="/shop" element={<ShopTwo />} >
          <Route index element={<ShopTwo />} />
          <Route path=":id" element={<ShopTwo />} />
      </Route>
      <Route path="/purchased" element={<YourNFTs />} >
          <Route index element={<YourNFTs />} />
          <Route path=":id" element={<YourNFTs />} />
      </Route>
      <Route path="/redeemcart" element={<RedeemDelivery/>} />


      </Route>
    </Routes>
    </Router>
    </DeliveryProvider>
    </AffiliateProvider>
    </WalletProvider>
    </Web3ReactProvider>
  );
}

export default App;
