import React, { useState } from 'react'
import TaskList from '../Components/TaskList'

const tasks = [  
  { name: 'Task 1', description: 'Description of Task 1', points: 5 },  
  { name: 'Task 2', description: 'Description of Task 2', points: 10 },
  { name: 'Task 3', description: 'Description of Task 3', points: 5 },  
  { name: 'Task 4', description: 'Description of Task 4', points: 10 },   
]; 

const AvailableTasks = () => {
	const [availableTasksArray, setAvailableTasksArray] = useState(tasks)
	return(
		<TaskList tasks={availableTasksArray} />
	)
}

export default AvailableTasks