import Web3 from "web3"
import { useWeb3React } from "@web3-react/core";
const { ethers } = require("ethers")


const SwagSale = require('../abi/SwagSale.json')
const ERC20 = require('../abi/ERC20.json')
const provider = "https://mainnet.era.zksync.io"

const contracts = {
    hue: {
        network: "zksync",
        chainId: 324,
        paymentToken: { address: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4", abi: ERC20 }, // USDT
        swagSale: { address: "0xCC9124315B30da02c1d933A4452D194Cc73885E1", abi: SwagSale, owner: "", symbol: "HSS" },
    }
}

const createTxOptions = async (wallet) => {
    const txOptions = { from: wallet, maxFeePerGas: 33750000, maxPriorityFeePerGas: 0 }

   

    return txOptions
}

const getItemsForSale = async (wallet) => {
    try {
        const web3 = new Web3(window.ethereum)
        // web3.setProvider(wallet.ethereum.provider)
        const swagSale = new web3.eth.Contract(contracts.hue.swagSale.abi, contracts.hue.swagSale.address)

        const itemOptionsSkus = await swagSale.methods.getItemOptions().call()
        console.log('item skus: ' + itemOptionsSkus)
        const options = []

        for (let i = 0; i < itemOptionsSkus.length; i++) {
            const item = await swagSale.methods.getItemDetails(itemOptionsSkus[i]).call()
            console.log('item: ' + item)
            const meta = await fetchImageFromMetadata(item.tokenURI)
            const description = meta?.description
            const imageUri = String(meta?.image)
            console.log('image uri: ' + imageUri)
            options.push({
                id: i,
                name: item.SKU,
                description,
                imgUrl: imageUri,
                itemPrice: Math.ceil(ethers.formatUnits(item.salePrice, 6)).toString() // decimals hardcoded for now
            })
        }

        return options
    } catch (error) {
        console.log(error)
    }
}

const getUserPaymentAllowance = async (account) => {
	
    try {
        const web3 = new Web3(window.ethereum)
        web3.setProvider(provider)
        const paymentToken = new web3.eth.Contract(contracts.hue.paymentToken.abi, contracts.hue.paymentToken.address)

        const allowance = await paymentToken.methods.allowance(account, contracts.hue.swagSale.address).call()

        return ethers.formatUnits(allowance.toString(), 6).toString()
    } catch (error) {
        console.log(error)
    }
}

const getUserPaymentBalance = async (account) => {
    try {
        // console.log("wallet", wallet)
        const web3 = new Web3(window.ethereum)
        web3.setProvider(provider)

        const paymentToken = new web3.eth.Contract(contracts.hue.paymentToken.abi, contracts.hue.paymentToken.address)
        // console.log("paymentToken", paymentToken)
        const symbol = await paymentToken.methods.symbol().call()
        // console.log("symbol", symbol)
        const decimals = await paymentToken.methods.decimals().call()
        // console.log("decimals", decimals)
        const balance = await paymentToken.methods.balanceOf(account).call()
        // console.log("balance", balance)

        return { symbol, decimals, balance: ethers.formatUnits(balance.toString(), decimals).toString() }
    } catch (error) {
        console.log(error)
    }
}

const approveSpender = async (account, setDisableModalButtons, setShowConfirmLoading) => {
    setDisableModalButtons(true)
    setShowConfirmLoading(true)
    try {
        const web3 = new Web3(window.ethereum)
        // web3.setProvider(wallet.ethereum.provider)

        const paymentToken = new web3.eth.Contract(contracts.hue.paymentToken.abi, contracts.hue.paymentToken.address)

        const balance = await paymentToken.methods.balanceOf(account).call()

        const txOptions = await createTxOptions(account)
        // const approveTx = await paymentToken.methods.approve(contracts.hue.swagSale.address, balance).send(txOptions)
        await paymentToken.methods.approve(contracts.hue.swagSale.address, balance).send(txOptions)  
          
        // Get the updated allowance amount  
        const updatedAllowance = await paymentToken.methods.allowance(account, contracts.hue.swagSale.address).call()  
        return ethers.formatUnits(updatedAllowance.toString(), 6).toString() 
    } catch (error) {
        console.log(error)
    } finally {
        setDisableModalButtons(false)
        setShowConfirmLoading(false)
    }
}

const buyItem = async (wallet, itemSku, setDisableModalButtons, setShowConfirmLoading, setBuyTx) => {
    setDisableModalButtons(true)
    setShowConfirmLoading(true)
    try {
        const web3 = new Web3(window.ethereum)

        const swagSale = new web3.eth.Contract(contracts.hue.swagSale.abi, contracts.hue.swagSale.address)
        const txOptions = await createTxOptions(wallet)
        console.log('item sku' + itemSku)
        const purchaseTx = await swagSale.methods.purchaseItem(itemSku).send(txOptions)

        setBuyTx(purchaseTx?.transactionHash)
    } catch (error) {
        console.log(error)
    } finally {
        setDisableModalButtons(false)
        setShowConfirmLoading(false)
    }
}

const buyItemAffiliate = async (wallet, itemSku, refererTier, refererId, affiliatePercent, setDisableModalButtons, setShowConfirmLoading, setBuyTx) => {
    setDisableModalButtons(true)
    setShowConfirmLoading(true)
    try {
        const web3 = new Web3(window.ethereum)

        const swagSale = new web3.eth.Contract(contracts.hue.swagSale.abi, contracts.hue.swagSale.address)
        const txOptions = await createTxOptions(wallet)
        console.log('item sku' + itemSku)
        const purchaseTx = await swagSale.methods.purchaseItemAffiliate(itemSku, refererTier, refererId, affiliatePercent).send(txOptions)

        setBuyTx(purchaseTx?.transactionHash)
    } catch (error) {
        console.log(error)
    } finally {
        setDisableModalButtons(false)
        setShowConfirmLoading(false)
    }
}

const buyShippingOption = async (wallet, deliveryCost, tokenIds, deliveryCode, setDisableModalButtons, setShowConfirmLoading, setBuyTx) => {
    setDisableModalButtons(true)
    setShowConfirmLoading(true)
    console.log('shipping option delivery code: ' + deliveryCode)
    try {
        const web3 = new Web3(window.ethereum)

        const swagSale = new web3.eth.Contract(contracts.hue.swagSale.abi, contracts.hue.swagSale.address)
        const txOptions = await createTxOptions(wallet)
        
        const purchaseTx = await swagSale.methods.payForDeliveryUSDC(deliveryCost, tokenIds, deliveryCode).send(txOptions)

        setBuyTx(purchaseTx?.transactionHash)

    } catch (error) {
        console.log(error)
    } finally {
        setDisableModalButtons(false)
        setShowConfirmLoading(false)
    }
}

const getUserItems = async (wallet) => {
    try {
        console.log('user items wallet: ' + wallet)
        const web3 = new Web3(window.ethereum)
        web3.setProvider(provider)
        // web3.setProvider(provider)
        const swagSale = new web3.eth.Contract(contracts.hue.swagSale.abi, contracts.hue.swagSale.address)

        // const itemCollection = new web3.eth.Contract(contracts.hue.swagSale.abi, contracts.hue.swagSale.address)
        const userItems = await swagSale.methods.getCollectionIds(wallet).call()
        console.log('user items: ' + userItems)
        const items = []
       
        //  userNFTs Details
        for (let i = 0; i < userItems.length; i++) {
            const item = await swagSale.methods.getPurchasedProductDetails(userItems[i]).call()
            const tokenURI = await swagSale.methods.tokenURI(userItems[i]).call()
            const tokenId = userItems[i]
            // console.log("BOX OPTION DETAILS:", boxOption)
            // console.log(`Box Id ${userswagSale[i]} details:`, box)
            const meta = await fetchImageFromMetadata(tokenURI)
            const imageUri = String(meta?.image)
           
            items.push({
                id: items.length,
                imgUrl: imageUri,
                name: item?.SKU,
                status: item?.redeemedStatus,
                tokenURI,
                tokenId: tokenId.toString()
            })
        }

        // console.log(bxCollection)
        console.log('items: ' + JSON.stringify(items))
        return items
    } catch (error) {
        console.log(error)
    } finally {
    }
}



const fetchImageFromMetadata = async (metadata) => {
    try {
        const res = await fetch(metadata)
        const response = await res.json()
        // console.log("fetchImageFromMetadata", response)

        if (res.status !== 200) {
            // return setErrorFetchingMetadata(true)
        }

        return response
    } catch (err) {
        console.log(err)
    }
}



export {
    contracts,
    getUserItems,
    buyItem,
    buyItemAffiliate,
    buyShippingOption,
    fetchImageFromMetadata,
    approveSpender,
    getUserPaymentBalance,
    getUserPaymentAllowance,
    getItemsForSale

}