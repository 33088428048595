import React, { useState, useEffect } from 'react'
import HeaderSection from "../Layout/HeaderSection"
import { useNavigate, useParams, useLocation, NavLink } from 'react-router-dom'
import { getItemsForSale, getUserItems } from "./ShopUtils"
import { useWeb3React } from "@web3-react/core";
import { useAffiliate } from '../contexts/AffiliateContext'
import USDClogo from "../usdc.png"
import RedeemCard from "../Components/RedeemCard"

const swagContractAddress = "0x97aa1c2e54DF17ccEd812A47D8FdFaf7c21dcA24"
const chainId = 324

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/


export default function YourNFTs() {
  const [itemsArray, setItemsArray] = useState([])
  const [activeItem, setActiveItem] = useState(null)
  const { id, tier } = useAffiliate()
  const { account } = useWeb3React()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  const handleCardClick = (idx) => {
    console.log(idx + ' card clicked')
    const item = itemsArray[idx]
    navigate(`/purchased/${idx + 1}`, { item, idx})
  }


  const getUserNfts = async(account) => {
    const availableItems = await getUserItems(account)
    console.log('available items: ' + JSON.stringify(availableItems))
    setItemsArray(availableItems)
  }

  useEffect(() => {
        if (account) {
              console.log('account is: ' + account)
              getUserNfts(account)
        }
    }, [account])

  useEffect(() => {
    if (id) {
      console.log('Affiliate ID:', id);
    }
  }, [id]);

  useEffect(() => {
       
        const { id } = params; 
        
        const itemIndex = id - 1

        if (itemIndex >= 0) {
            setActiveItem(itemIndex);
        } else {
            setActiveItem(null); 
        }
    }, [location, params, itemsArray]); 

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8">
        <div className="flex items-center justify-between space-x-4">
          
          
        </div>
        <HeaderSection title="Your Hue Merch NFTs" subtext="Each NFT can be redeemed for its matching physical item and have it delivered for a delivery fee." />
        {activeItem !== null ? (
          <RedeemCard item={itemsArray[activeItem]} idx={activeItem} />
          ) : (
        <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
          {itemsArray.map((item, idx) => (
            <NavLink
              key={idx}
              to={`/purchased/${idx + 1}`}
              className="group relative"
              activeClassName="active"
              onClick={() => handleCardClick(idx)}
            >
            <div key={idx} className="group relative">
              <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
                <img src={item.imgUrl} alt={item.name} className="object-cover object-center"/>
                <div className="flex items-end p-4 opacity-0 group-hover:opacity-100" aria-hidden="true">
               
                  <div className="w-full rounded-md bg-white bg-opacity-75 px-4 py-2 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter">
                    View Options
                  </div>
                

                </div>
              </div>
              <div className="mt-4 flex items-center justify-between space-x-8 text-base font-medium text-gray-900">
                <h3>
                  
                    <span aria-hidden="true" className="absolute inset-0" />
                    {item.name} {item.status}
                  
                </h3>
                <p class="flex items-center space-x-4">${item.itemPrice} <img src={USDClogo} class="h-12 w-auto" /> USDC.e</p>
              </div>
              <p className="mt-1 text-sm text-gray-500">#{item.tokenId}</p>

            </div>
          </NavLink>
          ))}
        </div>
        )}
      </div>
    </div>
  )
}
