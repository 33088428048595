import React, { useState } from 'react'
import TaskList from '../Components/TaskList'

const tasks = [  
  { name: 'Task 1', description: 'Description of Task 1', points: 5 },  
  { name: 'Task 2', description: 'Description of Task 2', points: 10 },  
]; 

const CompletedTasks = () => {
	const [availableTasksArray, setAvailableTasksArray] = useState(tasks)
	return(
		<TaskList tasks={availableTasksArray} />
	)
}

export default CompletedTasks