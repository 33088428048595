import { openDB } from 'idb';

const dbPromise = openDB('affiliate-db', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('affiliate')) {
      db.createObjectStore('affiliate', { keyPath: 'key' });
    }
  },
});

export const setAffiliateData = async (data) => {
  const db = await dbPromise;
  await db.put('affiliate', { key: 'affiliateData', data });
};

export const getAffiliateData = async () => {
  const db = await dbPromise;
  return (await db.get('affiliate', 'affiliateData'))?.data;
};
