import React, { useState } from 'react';
import HueLogo from '../hue.jpg';   
import HueImage from '../hue4.png';  
  
// Dummy data for shop items  
const shopItems = [{  
  id: 1,  
  name: "Hue Raffle - Week #2",  
  points: 69,  
  image: HueImage,  
  remaining: "349/400",  
  isSoldOut: false,
  description: "One purchase of this weekly raffle ticket equals one entry for a chance to win an Hue NFT from the treasury"  
  // Add other item properties as needed  
}, {  
  id: 1,  
  name: "Hue Raffle - Week #2",  
  points: 69,  
  image: HueImage,  
  remaining: "349/400",  
  isSoldOut: false,
  description: "One purchase of this weekly raffle ticket equals one entry for a chance to win an Hue NFT from the treasury"    
  // Add other item properties as needed  
}, {  
  id: 1,  
  name: "Hue Raffle - Week #2",  
  points: 69,  
  image: HueImage,  
  remaining: "349/400",  
  isSoldOut: false,
  description: "One purchase of this weekly raffle ticket equals one entry for a chance to win an Hue NFT from the treasury"    
  // Add other item properties as needed  
},
{  
  id: 1,  
  name: "Hue Raffle - Week #2",  
  points: 69,  
  image: HueImage,  
  remaining: "349/400",  
  isSoldOut: true,
  description: "One purchase of this weekly raffle ticket equals one entry for a chance to win an Hue NFT from the treasury"    
  // Add other item properties as needed  
}];  
  
function HuePointShop() {  
  const [selectedItem, setSelectedItem] = useState(null);  
  
  // Handler to show item details  
  const handleShowDetails = (item) => {  
    setSelectedItem(item);  
  };  
  
  // Handler to hide item details  
  const handleCloseDetails = () => {  
    setSelectedItem(null);  
  };  
  
  return (  
    <div className="w-3/4 p-4">  
      <div className="flex justify-between items-center">  
        <h2 className="text-2xl font-bold">Hue Point Shop</h2>  
        <button className="bg-blue-500 text-white py-2 px-4 rounded">  
          My History  
        </button>  
      </div>  
      <div className="grid grid-cols-4 gap-4 mt-4">  
        {shopItems.map((item) => (  
          <div  
            key={item.id}  
            className="rounded overflow-hidden shadow-lg cursor-pointer"  
            onClick={() => handleShowDetails(item)}  
          >  
            <div className="relative">  
              {item.isSoldOut && (  
                <div className="absolute inset-0 bg-red-500 opacity-75 flex items-center justify-center">  
                  <span className="text-white text-xl font-bold">Sold Out</span>  
                </div>  
              )}  
              <img  
                className="w-full h-48 object-cover object-center"  
                src={item.image}  
                alt={item.name}  
              />  
            </div>  
            <div className="px-6 py-4">  
              <div className="font-bold text-xl mb-2">{item.name}</div> 
              <div className="flex items-start">  
              <p>{item.points}</p> 
              <img src={HueLogo} alt="Your Points" className="rounded-full h-8 w-8 shadow-lg" />
              </div> 
            </div>  
          </div>  
        ))}  
      </div>  
  
      {selectedItem && (  
        <div className="mt-8 bg-white p-4 shadow-lg">  
          <button  
            className="absolute top-0 right-0 m-4"  
            onClick={handleCloseDetails}  
          >  
            Close  
          </button>  
          <div className="flex">  
            <div className="w-1/3 pr-4">  
              <img  
                className="max-w-full max-h-full"  
                src={selectedItem.image}  
                alt={selectedItem.name}  
              />  
            </div>  
            <div className="w-1/3 pr-4">  
              <h3 className="text-xl font-bold">{selectedItem.name}</h3>  
              <div className="flex items-start">  
              <p>{selectedItem.points}</p> 
              <img src={HueLogo} alt="Your Points" className="rounded-full h-8 w-8 shadow-lg" />
              </div> 
              <p>{selectedItem.description}</p>  
              {/* Full description and more information as needed */}  
            </div>  
            <div className="w-1/3">  
              <div>My Hue Points: 948</div>  
              <button className="bg-indigo-500 text-white py-2 px-4 rounded mt-4">  
                Buy  
              </button>  
            </div>  
          </div>  
        </div>  
      )}  
    </div>  
  );  
}  
  
export default HuePointShop; 