import React, { useState } from 'react';  
import { NavLink, Outlet } from 'react-router-dom'; 
import AvailableTasks from './AvailableTasks'
import CompletedTasks from './CompletedTasks'
import HueMain from '../hue2.png'
import HueThree from '../hue3.png'
import HueFour from '../hue4.png' 
  
const HuePortal = () => { 
  const [defaultComponent, setDefaultComponent] = useState('tasks/AvailableTasks') 
  return (  
    <div className="container mx-auto">  
      <div className="flex">  
        <div className="w-1/4">  
          <img src={HueMain} alt="Large Image" className="w-full h-auto" />  
        </div>  
  
        {/* Second column - Subheader and task list */}  
        <div className="w-3/4 pl-4">  
          <div className="flex">  
            <nav className="flex space-x-4">  
              <NavLink to="/tasks/AvailableTasks" className={({ isActive }) =>  
                isActive ? 'text-blue-500' : 'text-gray-500'  
              }  
              onClick={() => setDefaultComponent('available')} activeClassName="font-bold">  
                Tasks (14)
              </NavLink>  
              <NavLink to="/tasks/CompletedTasks" className={({ isActive }) =>  
                isActive ? 'text-blue-500' : 'text-gray-500'  
              }  
              onClick={() => setDefaultComponent('completed')} activeClassName="font-bold">  
                Completed (3)  
              </NavLink>  
            </nav>

          </div>  
  
          <div className="mt-4 h-64 overflow-y-auto"> 
          {defaultComponent === 'tasks/AvailableTasks' && <AvailableTasks />} 
            <Outlet />  
          </div>  
        </div>  
      </div>  
  
      <div className="flex mt-8">  
        {/* First column - Paragraph text */}  
        <div className="w-3/4 pr-4">  
          <p>  
            You earn 1 point per day for each Hue you hold. Complete daily tasks for extra points! The more Hues you have, the bigger your multiplier on the Hue points (doesn't affect task points). 
          </p>  
        </div>  
  
        <div className="w-1/4">  
          <p className="font-bold">Hue Points: 948</p>  
          <p>All Time High Points: 1049</p>  
        </div>  
      </div>  
  
      <div className="mt-8">  
        <h2 className="text-2xl font-bold">My Hues</h2>  
        <div className="flex mt-4">  
          {/* Avatar-sized pictures */}  
          <div className="flex space-x-6">  
            <img src={HueThree} alt="Avatar 1" className="w-32 h-32" />  
            <img src={HueFour} alt="Avatar 2" className="w-32 h-32" />  
            <img src={HueThree} alt="Avatar 3" className="w-32 h-32" />  
            <img src={HueFour} alt="Avatar 4" className="w-32 h-32" />  
            <img src={HueThree} alt="Avatar 5" className="w-32 h-32" />  
            <img src={HueFour} alt="Avatar 6" className="w-32 h-32" />  
          </div>  
  
          {/* Primary button */}
          <div className="px-12 py-8">  
          <button className="bg-indigo-600 text-white px-4 py-2 ml-4 h-12 rounded">  
            I want more Hues  
          </button> 
          </div> 
        </div>  
      </div>  
    </div>  
  );  
};  
  
export default HuePortal;