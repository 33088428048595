const key = ""

function headers(apiKey) {
    return {
        "Content-Type": "application/json",
        "x-api-key": apiKey
    }
}

const txObj = (address, args) => {
    args.from = address 

    return args
}


const createItem = async(web3, abi, swagContractAddress, operatorAddress, itemName, priceInUSDC, quantityAvailable, itemTokenURI, setProcessing, setMessage, onSuccess) => {
    let swagContract
    console.log('got to create with operator: ' + operatorAddress + ' token URI: ' + itemTokenURI)
    try {
        swagContract = new web3.eth.Contract(abi, swagContractAddress)
    } catch (error) {
        setMessage("error", "Error initializing contract")
        console.error(error)
    }

    if(swagContract) {
        try {
            setProcessing(true)
            let args = { maxPriorityFeePerGas: 0, maxFeePerGas: 33750000 }

            await swagContract.methods
                .createNewItem(itemName, priceInUSDC, itemTokenURI, quantityAvailable)
                .send(txObj(operatorAddress, args))
                .on("receipt", function(receipt) {
                    if(receipt.status === true) {
                        setProcessing(false)
                        setMessage("success", "Swag item created successfully")
                        onSuccess()
                    } else {
                        setMessage("error", "Swag item creation error")
                        setProcessing(false)
                    }
                })
                    .on("error", function(error) {
                        setMessage("error", error.message)
                        setProcessing(false)
                    })
                setProcessing(false)
                
        } catch (error) {
            console.log(error)
            setProcessing(false)
        }
    } else {
        setMessage("Uninitialized contract")
    }


}

export { createItem }