import HueOne from '../hue1.png'
import HueTwo from '../hue2.png'
import HueThree from '../hue3.png'
import HueFour from '../hue4.png'


const people = [
  {
    name: 'Hue Maxi',
    title: 'July 2023',
    department: '10 months',
    email: '@huemaxi',
    role: 'Member',
    rank: 1,
    points: 3710,
    image: HueOne
      },
  {
    name: 'Chad NFT',
    title: 'October 2023',
    department: '7 months',
    email: '@chadnft1234',
    role: 'Member',
    points: 3290,
    rank: 2,
    image: HueTwo
      }, 
  {
    name: 'Bubble Boy',
    title: 'July 2023',
    department: '10 months',
    email: '@bubblethenoiz',
    role: 'Member',
    points: 2774,
    rank: 3,
    image:HueThree
      }, 
  {
    name: 'Bull Esh',
    title: 'February 2024',
    department: '3 months',
    email: '@bullmarketvibes',
    role: 'Member',
    points: 2150,
    rank: 4,
     image: HueFour
      }, 
  // More people...
]

export default function LeaderBoard() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex items-center">
        <div className="flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Top Hues</h1>
          <p className="mt-2 text-sm text-gray-700">
            The leaderboard of the Hue community by points earned.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Increase Your Rank
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">  
        <div className="-my-2 sm:-mx-6 lg:-mx-8">  
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">  
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">  
              <table className="min-w-full divide-y divide-gray-300">  
              <thead>
                <tr>
                  <th  
                      scope="col"  
                      className="px-3 py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"  
                    >  
                    Name
                  </th>
                  
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Rank
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Points
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {people.map((person) => (
                  <tr key={person.email}>
                    <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                          <img className="h-11 w-11 rounded-full" src={person.image} alt="" />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">{person.name}</div>
                          <div className="mt-1 text-gray-500">{person.email}</div>
                        </div>
                      </div>
                    </td>
                    
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {person.rank}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.points}</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
            type="button"
            className="block rounded-md bg-gray-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
          >
            Follow {person.email}
          </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
