import { Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';

const injectedConnector = new InjectedConnector({
  supportedChainIds: [324], // Add your supported chain IDs here
});

export const connectWallet = async (connector) => {
  try {
    await connector.activate(injectedConnector);
  } catch (error) {
    console.error('Failed to connect:', error);
  }
};

export const switchChain = async (provider, targetChainId) => {
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${targetChainId.toString(16)}` }],
    });
  } catch (error) {
    console.error('Failed to switch chain:', error);
  }
};

export const getBalance = async (provider, account) => {
  console.log('library: ' + provider)
  if (account && provider) {
    const balance = await provider.getBalance(account);
    return balance;
  }
  return null;
};