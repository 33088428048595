import React from 'react'
import AdminHeader from './AdminHeader'
import { Outlet } from 'react-router-dom'

const AdminLayout = () => {
	return(
	<>
	<AdminHeader />

	<div>
		<Outlet />
	</div>
	</>
	)
}

export default AdminLayout