
import { useState, useEffect } from 'react'
import { Disclosure, RadioGroup, Tab } from '@headlessui/react'
import { StarIcon } from '@heroicons/react/20/solid'
import { HeartIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { buyItem, buyItemAffiliate, getUserPaymentBalance, getUserPaymentAllowance, approveSpender } from "../Pages/ShopUtils"
import USDClogo from "../usdc.png"
import { useWeb3React } from "@web3-react/core";
import { useAffiliate } from "../contexts/AffiliateContext"


const details = [
    {
      name: 'Shipping Options',
      items: [
        `1-3 business days within USA; 6-10 business days international. Shipping costs extra, with price depending on item size and weight. Shipping costs payable on separate item redemption transaction.`,
   
      ],
    },
]

const product = {
  name: 'One-of-a-kind Hue Art by Epic One',
  price: '$3500 USDC.e',
  rating: 5,
  images: [
    {
      id: 1,
      name: 'Angled view',
      src: 'https://arweave.net/oBfAcd6kDNIc39rDuUBh9Fg1alzfbmf6bBwNE86rXLk',
      alt: 'Angled front view with bag zipped and handles upright.',
    },
    // More images...
  ],
  colors: [
    { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
    { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
    { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
  ],
  description: `
    <p>Mixed-medium wall-mountable Hue art with matching Hue NFT #280. 18" × 18" x 4". 64 x 2* primed & painted wood cubes screw-mounted on wood with primed & painted white wood frame. Ready to hang and built to last. Owner will receive the matching Hue NFT that doubles as a certificate of ownership. Can be purchased here or by buying the Hue NFT #280 listed on 2kmarkets.com.
   </p>
  `,
  details: [
    {
      name: 'Shipping Options',
      items: [
        `Ship fo $X in Y days to XXX locations or $YY in Z days to AAA locations. Make your selection and payment only when redeeming your NFT for delivery.`,
   
      ],
    },
    // More sections...
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ItemCard({ item, idx }) {
  const [selectedColor, setSelectedColor] = useState(product.colors[0])
  const [userUsdcBalance, setUserUsdcBalance] = useState(0)
  const [userApprovedAmt, setUserApprovedAmount] = useState(0)
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false)
  const [disableModalButtons, setDisableModalButtons] = useState(false)
  const [showConfirmLoading, setShowConfirmLoading] = useState(false)
  const [modalAlertMessage, setModalAlertMessage] = useState('')
  const [alertColor, setAlertColor] = useState('')
  const [buyTx, setBuyTx] = useState(null)
  const { account, provider } = useWeb3React()
  const { id, tier } = useAffiliate()
  console.log('item: ' + JSON.stringify(item))
  const confirmApprovePurchase = async (type) => {
        console.log(type)
        setShowConfirmLoading(true)
        setDisableModalButtons(true)

        switch (type) {
            case "APPROVE":
                console.log("Call Approve")
                const updatedAllowance = await approveSpender(account, setDisableModalButtons, setShowConfirmLoading);  
                setUserApprovedAmount(updatedAllowance); // Update the state with the new allowance amount 
                break
            case "PURCHASE":
                console.log("Call Purchase")
                const buyTx = buyItem(account, item?.name, setDisableModalButtons, setShowConfirmLoading, setBuyTx)
                break

            default:
                setShowConfirmLoading(false)
                setDisableModalButtons(false)
                break
        }
    }
  const handleClickItemCard = async (e) => {
    const buttonTxt = e?.target?.lastChild?.innerHTML

    // Prevent default actions for specific conditions
    if (e?.target?.localName === "input" || buttonTxt === "INSUFFICIENT BALANCE") {
        e.preventDefault()
        return; // Early return to stop further execution
    }

    // Directly call the functions based on button text
    if (buttonTxt === "APPROVE" || e?.target?.innerHTML === "APPROVE") {
        e.preventDefault(); // Prevent any default action (if necessary)
        setShowConfirmLoading(true);
        setDisableModalButtons(true);
        
        const updatedAllowance = await approveSpender(account, setDisableModalButtons, setShowConfirmLoading);  
        setUserApprovedAmount(updatedAllowance); // Update the state with the new allowance amount 
        // Reset states if needed after the operation
        setShowConfirmLoading(false);
        setDisableModalButtons(false);
       
    } else if (buttonTxt === "PURCHASE" || e?.target?.innerHTML === "PURCHASE") {
        e.preventDefault(); // Prevent any default action (if necessary)
        setShowConfirmLoading(true);
        setDisableModalButtons(true);

        if(id && tier) {
          await buyItemAffiliate(account, item?.name, tier, id, "1600", setDisableModalButtons, setShowConfirmLoading, setBuyTx)
          setShowConfirmLoading(false);
          setDisableModalButtons(false);
        }
        else {
          await buyItem(account, item?.name, setDisableModalButtons, setShowConfirmLoading, setBuyTx);
          // Reset states if needed after the operation
          setShowConfirmLoading(false);
          setDisableModalButtons(false);
        }
        
        
        
    }
}
  useEffect(() => {
    
    const getBalances = async(account) => {
      const userBalance = await getUserPaymentBalance(account)
      console.log('balance: ' + userBalance.balance)
      setUserUsdcBalance(userBalance)
      const userApproved = await getUserPaymentAllowance(account)
      console.log('approved: ' + userApproved)
      setUserApprovedAmount(userApproved)
    }
    if(account) {
      getBalances(account)
    }
  }, [])

  useEffect(() => {
    if (id) {
      console.log('Affiliate ID:', id);
    }
  }, [id]);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            {/* Image selector */}
            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                
                  <Tab
                    key={item?.imgUrl}
                    className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    {({ selected }) => (
                      <>
                        <span className="sr-only">{item?.imgUrl}</span>
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img src={item.imgUrl} alt="" className="h-full w-full object-cover object-center" />
                        </span>
                        <span
                          className={classNames(
                            selected ? 'ring-indigo-500' : 'ring-transparent',
                            'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
               
              </Tab.List>
            </div>

            <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
              
                <Tab.Panel key={item.id}>
                  <img
                    src={item.imgUrl}
                    alt={item.name}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </Tab.Panel>
              
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">{item.name}</h1>

            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl tracking-tight text-gray-900 flex items-center space-x-4">${item.itemPrice}<img src={USDClogo} class="h-12 w-auto" /> USDC.e</p>
              
            </div>

           
            <div className="mt-6">
              <h3 className="sr-only">Description</h3>

              <div
                className="space-y-6 text-base text-gray-700"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
              
              
            </div>
          
            <form className="mt-6">
             <p>Your USDC balance: ${userUsdcBalance.balance} USDC.e </p>
              <div className="mt-10 flex">
                <button
                  type="submit"
                  onClick={handleClickItemCard}
                  className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                >
                  {Number(item?.itemPrice) > Number(userUsdcBalance.balance) 
                    ? "NOT ENOUGH USDC" 
                    : Number(item?.itemPrice) > Number(userApprovedAmt) 
                    ? 'APPROVE' 
                    : 'PURCHASE'
                  }
                </button>

                <button
                  type="button"
                  className="ml-4 flex items-center justify-center rounded-md px-3 py-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                >
                  <HeartIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                  <span className="sr-only">Add to favorites</span>
                </button>
              </div>
            </form>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>

              <div className="divide-y divide-gray-200 border-t">
                {details.map((detail) => (
                  <Disclosure as="div" key={detail.name}>
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                            <span
                              className={classNames(open ? 'text-indigo-600' : 'text-gray-900', 'text-sm font-medium')}
                            >
                              {detail.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                          <ul role="list">
                            {detail.items.map((item) => (
                              <li key={item}>{item}</li>
                            ))}
                          </ul>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
      
    </div>
  )
}
