import React, { createContext, useContext, useState } from 'react';  
  
const DeliveryContext = createContext();  
  
export function DeliveryProvider({ children }) {  
  const [deliveryItems, setDeliveryItems] = useState([]);  
  
  const addDeliveryItem = (item) => {  
    setDeliveryItems((currentItems) => [...currentItems, item]);  
  };  
  
  const removeDeliveryItem = (itemId) => {  
    setDeliveryItems((currentItems) =>  
      currentItems.filter((item) => item.id !== itemId)  
    );  
  };  
  
  const clearDeliveryItems = () => {  
    setDeliveryItems([]);  
  };  
  
  return (  
    <DeliveryContext.Provider value={{ deliveryItems, addDeliveryItem, removeDeliveryItem, clearDeliveryItems }}>  
      {children}  
    </DeliveryContext.Provider>  
  );  
}  
  
export function useDelivery() {  
  return useContext(DeliveryContext);  
}  