import { useState } from "react"
import { Outlet } from "react-router-dom"
import Header from "./Header"
import { MainContent } from "./MainContent"
import Footer from "./Footer"

export default function Layout() {
	return (
	<div className="">
	<Header />
	<div className="content">
	<div className="mx-auto max-w-7xl px-6 lg:px-8"> 
		<Outlet />
	</div>

	</div>
	<Footer />

	</div>

	)
}