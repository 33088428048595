import React, { useState, useCallback, useEffect } from 'react'
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { Dialog } from '@headlessui/react'
import Web3 from "web3"
import { useWeb3React } from "@web3-react/core";
import Dropzone from 'react-dropzone'
import { createItem } from './adminUtils'
import SwagSaleAbi from '../../abi/SwagSale.json'

const key = ""
const swagSaleContract = "0xCC9124315B30da02c1d933A4452D194Cc73885E1"



function isBase64String(str) {
    return str.length % 4 == 0 && /^[A-Za-z0-9+/]+[=]{0,2}$/.test(str)
}

function isStringURL(str) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(str)
}

function headers(apiKey) {
    return {
        "Content-Type": "application/json",
        "x-api-key": apiKey
    }
}

function detectMimeType(b64) {
    for (var s in base64Signatures) {
        if (b64.indexOf(s) === 0) {
            return base64Signatures[s]
        }
    }
}

const base64Signatures = {
    JVBERi0: "application/pdf",
    R0lGODdh: "image/gif",
    R0lGODlh: "image/gif",
    iVBORw0KGgo: "image/png",
    "/9j/": "image/jpg",
    U: "image/webp"
}


const AdminForm = () => {
  const web3 = new Web3(window.ethereum)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [displayFiles, setDisplayFiles] = useState([])
  const [acceptedFiles, setAcceptedFiles] = useState([])
  const [imgFile, setImgFile] = useState(null)
  const [usdPrice, setUSDPrice] = useState(0)
  const [itemName, setItemName] = useState('')
  const [itemDescription, setItemDescription] = useState('')
  const [quantityAvailable, setQuantityAvailable] = useState(0)
  const [processing, setProcessing] = useState(false)
  const { account, provider } = useWeb3React()
  const [open, setOpen] = useState(false); // for the success dialog 

  const formatBytes = useCallback((bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }, [])


  const sendRequest = async (apiKey, network, data, method = "post", params = null) => {
      let config = {
          method: method,
          headers: headers(apiKey),
          body: data ? JSON.stringify(data) : undefined,
      };

      let url = new URL("https://ziparweave.idexo.io");

      // If there are params, append them to the URL
      if (params) {
          Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      }

      try {
          const response = await fetch(url, config);
          const responseData = await response.json(); // Assuming the server response is JSON
          return response.ok ? responseData : Promise.reject(responseData);
      } catch (error) {
          // Handling fetch errors (network errors, etc.)
          console.error("Fetch error: ", error);
          return error; // Or handle more gracefully as needed
      }
  };

  const uploadImage = async(apiKey, image) => {
        let contentType

        if (isBase64String(image)) contentType = detectMimeType(image)
        if (contentType === undefined && !isStringURL(image)) throw "unsupported file type! image must be jpg, png, gif, webp, or pdf"

        const uploadType = "image"

        return await sendRequest(apiKey, 'arweave', {
            uploadType,
            image,
            contentType
        })


    }

  const uploadNFTMetadata = async(apiKey, image, itemName, itemDescription, attributes) => {
      let contentType

      if(isBase64String(image)) contentType = detectMimeType(image)
      if(contentType === undefined && !isStringURL(image)) throw "Unsupported file type! Image must be jpg, png, gif, webp, or pdf"

      console.log('name: ' + itemName)
      console.log('description: ' + itemDescription)

      const uploadType = "NFTMetadata"

      const nftName = itemName 
      const nftDescription = itemDescription

      return await sendRequest(apiKey, "arweave", {
        image,
        contentType,
        nftName,
        nftDescription,
        attributes,
        uploadType
      })
    }

  const handleCreateItem = async() => {
    setProcessing(true) 
    const imageFile = selectedFiles[0].base64 
    const attributes = [{
        "trait_type": "Price",
        "value": usdPrice
    }]
    const tokenUriCall = await uploadNFTMetadata(key, imageFile, itemName, itemDescription, attributes)
    const tokenUri = tokenUriCall.data.metadata_uri
    const priceInUSDC = usdPrice * 1000000
    createItem(web3, SwagSaleAbi, swagSaleContract, account, itemName, priceInUSDC, quantityAvailable, tokenUri, setProcessing, setMessage, onSuccess)

  }

  const setMessage = async(type, message) => {
      console.log('placeholder for switch between success, error, info')
    }

  // Success Dialog component  
  function SuccessDialog() {  
        return (  
            <Dialog as="div" open={open} onClose={() => setOpen(false)} className="fixed z-10 inset-0 overflow-y-auto">  
                <div className="flex items-center justify-center min-h-screen">  
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />  
                    <div className="relative bg-white rounded max-w-sm mx-auto p-5">  
                        <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">Success</Dialog.Title>  
                        <Dialog.Description className="mt-2">  
                            The item has been successfully created!  
                        </Dialog.Description>  
                        <button  
                            onClick={() => setOpen(false)}  
                            className="mt-4 px-4 py-2 bg-indigo-500 hover:bg-indigo-700 rounded text-white"  
                        >  
                            OK  
                        </button>  
                    </div>  
                </div>  
            </Dialog>  
        )  
    }  

  const resetForm = () => {  
        setItemName('');  
        setUSDPrice(0);  
        setQuantityAvailable('');  
        setItemDescription('');  
        setSelectedFiles([]);  
        setDisplayFiles([]);  
    };
  const onSuccess = async () => {
      setProcessing(false);  
      setOpen(true); 

    }

  const handleAcceptedFiles = async (files) => {
      const toBase64 = (file) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(Object.assign(file, {
              preview: URL.createObjectURL(file),
              formattedSize: formatBytes(file.size),
              base64: reader.result.split(',')[1]
          }));
          reader.onerror = (error) => reject(error);
      });

      const base64Files = await Promise.all(files.map(file => toBase64(file)));
      const fileZero = files[0]
      const filePreview = Object.assign(fileZero, {
          preview: URL.createObjectURL(fileZero)
      })
      setSelectedFiles(base64Files);
      setDisplayFiles([filePreview])
  }

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);
  return (
    <>
    <div className="flex justify-center">  
      <div className="w-full max-w-7xl flex"> 
   
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Create a New Item</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Create an item for sale in the store.
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  
                  <input
                    type="text"
                    name="itemname"
                    id="itemname"
                    autoComplete="itemname"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder=""
                    onChange={(e) => setItemName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Price in USDC
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  
                  <input
                    type="text"
                    name="usdprice"
                    id="usdprice"
                    autoComplete="usdprice"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder=""
                    onChange={(e) => setUSDPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Quantity Available
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  
                  <input
                    type="text"
                    name="quantityavailable"
                    id="quantityavailable"
                    autoComplete="quantityavailable"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder=""
                    onChange={(e) => setQuantityAvailable(e.target.value)}
                  />
                </div>
              </div>
            </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm py-4 font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={''}
                  onChange={(e) => setItemDescription(e.target.value)}
                />
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-600">Description of the product that will display on the item detail page.</p>
            </div>

            

            <div className="col-span-full">
              <label htmlFor="cover-photo" className="block text-sm py-4 font-medium leading-6 text-gray-900">
                Item Image
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                {selectedFiles.length === 0 ? (
                      <Dropzone onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="text-center">
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <p className="mt-1 text-sm text-gray-600">
                                Drop files here or click to upload.
                              </p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <Dropzone onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone-previews mt-3" })}>
                            <input {...getInputProps()} />
                            {displayFiles.map((file, index) => (
                              <div key={index} className="dz-preview dz-file-preview">
                                <div className="dz-image">
                                  <img
                                    src={file.preview}
                                    alt={file.name}
                                    className="w-full h-auto object-contain"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </Dropzone>
                    )}
                    
              </div>
             
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
           <button  
              onClick={() => handleCreateItem()} 
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  
              disabled={processing}  
            >  
              {processing ? (  
                  <div className="flex items-center justify-center">  
                      <svg className="animate-spin h-5 w-5 mr-3 border-t-2 border-bg-white" viewBox="0 0 24 24"></svg>  
                      Loading...  
                  </div>  
              ) : (  
                  'Create'  
              )}  
           </button>
            </div>

          </div>
        </div>

        
          
           
            

          


     
  
    </div>
    </div>
    <SuccessDialog />
    </>
  )
}

export default AdminForm