import React, { useState, useEffect } from 'react'
import { useDelivery } from '../contexts/DeliveryContext';  
import AddressForm from '../Components/AddressForm';

const SkuShippingPriceMatrix = {
  stickers: {
    International: 8,
    USA: 3

  },
  epicone: {
    International: 100,
    USA: 50
  },
  rest: {
    International: 25, 
    USA: 10
  }

}  
  
export default function RedeemDelivery() {  
  const { deliveryItems, clearDeliveryItems } = useDelivery(); 
  const [deliveryCost, setDeliveryCost] = useState(0)
  const calculateDeliveryPrice = () => {
    return '1000'
  }

  const handleAddress = (address) => {
    const epiconeIncluded = deliveryItems.some(item => item.name === 'epicone');  
    if(address.country == "USA") {
      setDeliveryCost(epiconeIncluded ? SkuShippingPriceMatrix.epicone.USA : SkuShippingPriceMatrix.rest.USA)
    } else {
      setDeliveryCost(epiconeIncluded ? SkuShippingPriceMatrix.epicone.International : SkuShippingPriceMatrix.rest.International)
    }

  }  
  useEffect(() => {
    console.log('delivery items: ' + JSON.stringify(deliveryItems))
  }, [])
  return (  
    <div className="space-y-4">  
      <h2 className="text-2xl font-bold">Items to Deliver</h2>  
      {deliveryItems.map((item) => (  
        <div key={item.id} className="p-4 border rounded">  
          <h3 className="text-lg font-semibold">{item.name}</h3>  
          {/* Display item details */}  
        </div> 
    
      ))}  
  
      <AddressForm handleAddress={handleAddress} />  
        
      <p className="text-lg">  
        Total Delivery Price: {calculateDeliveryPrice()}  
      </p>  
        
      <button  
        type="button"  
        onClick={() => {/* Handle purchase confirmation */}}  
        className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-800 transition"  
      >  
        Purchase Delivery  
      </button>  
    </div>  
  );  
}  
