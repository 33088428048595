import React from 'react';  
import { Menu } from '@headlessui/react';  
  
const SummaryAnalytics = () => {  
  // Sample data  
  const totalUsers = 1000;  
  const activeUsers = 500;  
  const usersList = [  
    { name: 'John Doe', tasksCompleted: 10, currentPoints: 100, highestPoints: 150 },  
    { name: 'Jane Smith', tasksCompleted: 8, currentPoints: 80, highestPoints: 120 },    
  ];  
  const storeSales = [  
    { item: 'Product A', sales: 1000 },  
    { item: 'Product B', sales: 1500 },   
  ];  
  const overallSales = [  
    { period: 'Last Week', sales: 5000 },  
    { period: 'Last Month', sales: 20000 },  
  ];  
  const totalRaffles = 10;  
  const totalTickets = 500;  
  const totalAwards = 20;  
  const usersParticipating = 200;  
  
  return (  
    <div className="container mx-auto p-4">  
      <h1 className="text-3xl font-bold mb-4">Summary Analytics</h1>  
  
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">  
        <div className="bg-white shadow rounded-lg p-6">  
          <h2 className="text-xl font-bold mb-2">Total Users</h2>  
          <p className="text-4xl">{totalUsers}</p>  
        </div>  
  
        <div className="bg-white shadow rounded-lg p-6">  
          <h2 className="text-xl font-bold mb-2">Active Users</h2>  
          <p className="text-4xl">{activeUsers}</p>  
        </div>  
  
        <div className="bg-white shadow rounded-lg p-6">  
          <h2 className="text-xl font-bold mb-2">Users List</h2>  
          <ul>  
            {usersList.map((user, index) => (  
              <li key={index} className="mb-2">  
                <p className="font-bold">{user.name}</p>  
                <p>Tasks Completed: {user.tasksCompleted}</p>  
                <p>Current Points: {user.currentPoints}</p>  
                <p>Highest Points: {user.highestPoints}</p>  
              </li>  
            ))}  
          </ul>  
        </div>  
  
        <div className="bg-white shadow rounded-lg p-6">  
          <h2 className="text-xl font-bold mb-2">Store Sales by Item</h2>  
          <ul>  
            {storeSales.map((sale, index) => (  
              <li key={index} className="mb-2">  
                <p>{sale.item}: {sale.sales}</p>  
              </li>  
            ))}  
          </ul>  
        </div>  
  
        <div className="bg-white shadow rounded-lg p-6">  
          <h2 className="text-xl font-bold mb-2">Overall Sales</h2>  
          <ul>  
            {overallSales.map((sale, index) => (  
              <li key={index} className="mb-2">  
                <p>{sale.period}: {sale.sales}</p>  
              </li>  
            ))}  
          </ul>  
        </div>  
  
        <div className="bg-white shadow rounded-lg p-6">  
          <h2 className="text-xl font-bold mb-2">Total Raffles</h2>  
          <p className="text-4xl">{totalRaffles}</p>  
        </div>  
  
        <div className="bg-white shadow rounded-lg p-6">  
          <h2 className="text-xl font-bold mb-2">Total Tickets</h2>  
          <p className="text-4xl">{totalTickets}</p>  
        </div>  
  
        <div className="bg-white shadow rounded-lg p-6">  
          <h2 className="text-xl font-bold mb-2">Total Awards</h2>  
          <p className="text-4xl">{totalAwards}</p>  
        </div>  
  
        <div className="bg-white shadow rounded-lg p-6">  
          <h2 className="text-xl font-bold mb-2">Users Participating</h2>  
          <p className="text-4xl">{usersParticipating}</p>  
        </div>  
      </div>  
    </div>  
  );  
};  
  
export default SummaryAnalytics;  