const formatAddress = (address) => {
    if (!address) return ""
    if (address && address.length > 20) {
        return address.substring(0, 6) + "..." + address.substring(address.length - 4, address.length)
    }
    return address
}

const formatBalance = (val, wallet) => {
    // returns string, rounds to 2 decimal places
    const float_val = val / 1000000000000000000 // convert wei to ETH
    let currency = "ETH"
    return float_val.toFixed(2) + ` ${currency}`
}

export { formatBalance, formatAddress }