import React, { useState } from 'react';  
import { Switch } from '@headlessui/react';  

const SkuShippingPriceMatrix = {
  stickers: {
    International: 8,
    USA: 3

  },
  epicone: {
    International: 100,
    USA: 50
  },
  rest: {
    International: 25, 
    USA: 10
  }

}
  
const AddressForm = ({ handleAddress }) => {  
  const [receiverName, setReceiverName] = useState('');  
  const [companyName, setCompanyName] = useState('');  
  const [contactNumber, setContactNumber] = useState('');  
  const [emailAddress, setEmailAddress] = useState('');  
  const [country, setCountry] = useState('');  
  const [zipCode, setZipCode] = useState('');  
  const [stateProvince, setStateProvince] = useState('');  
  const [city, setCity] = useState('');  
  const [addressLine1, setAddressLine1] = useState('');  
  const [addressLine2, setAddressLine2] = useState('');  
  const [isResidential, setIsResidential] = useState(false);  
  
  const countries = ['USA', 'Canada', 'UK', 'Australia']; // Add more countries as needed  
  
  const handleSubmitAddress = () => {  
    // Perform form submission logic here  
    console.log({  
      receiverName,  
      companyName,  
      contactNumber,  
      emailAddress,  
      country,  
      zipCode,  
      stateProvince,  
      city,  
      addressLine1,  
      addressLine2,  
      isResidential,  
    }); 
    handleAddress({receiverName,  
      companyName,  
      contactNumber,  
      emailAddress,  
      country,  
      zipCode,  
      stateProvince,  
      city,  
      addressLine1,  
      addressLine2,  
      isResidential,  
    }) 
  };  
  
  return (  
    <div className="">  
      <h2 className="text-2xl font-bold mb-4">Delivery Details</h2>  
      <div className="mb-4">  
        <label htmlFor="receiverName" className="block mb-1">  
          Receiver Name  
        </label>  
        <input  
          type="text"  
          id="receiverName"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={receiverName}  
          onChange={(e) => setReceiverName(e.target.value)}  
        />  
      </div>  
      <div className="mb-4">  
        <label htmlFor="companyName" className="block mb-1">  
          Company Name (optional)  
        </label>  
        <input  
          type="text"  
          id="companyName"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={companyName}  
          onChange={(e) => setCompanyName(e.target.value)}  
        />  
      </div>  
      <div className="mb-4">  
        <label htmlFor="contactNumber" className="block mb-1">  
          Contact Number  
        </label>  
        <input  
          type="tel"  
          id="contactNumber"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={contactNumber}  
          onChange={(e) => setContactNumber(e.target.value)}  
        />  
      </div>  
      <div className="mb-4">  
        <label htmlFor="emailAddress" className="block mb-1">  
          Email Address 
        </label>  
        <input  
          type="email"  
          id="emailAddress"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={emailAddress}  
          onChange={(e) => setEmailAddress(e.target.value)}  
        />  
      </div>  
      <div className="mb-4">  
        <label htmlFor="country" className="block mb-1">  
          Country  
        </label>  
        <select  
          id="country"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={country}  
          onChange={(e) => setCountry(e.target.value)}  
        >  
          <option value="">Select a country</option>  
          {countries.map((country) => (  
            <option key={country} value={country}>  
              {country}  
            </option>  
          ))}  
        </select>  
      </div>  
      <div className="mb-4">  
        <label htmlFor="zipCode" className="block mb-1">  
          Zip Code  
        </label>  
        <input  
          type="text"  
          id="zipCode"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={zipCode}  
          onChange={(e) => setZipCode(e.target.value)}  
        />  
      </div>  
      <div className="mb-4">  
        <label htmlFor="stateProvince" className="block mb-1">  
          State/Province  
        </label>  
        <input  
          type="text"  
          id="stateProvince"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={stateProvince}  
          onChange={(e) => setStateProvince(e.target.value)}  
        />  
      </div>  
      <div className="mb-4">  
        <label htmlFor="city" className="block mb-1">  
          City  
        </label>  
        <input  
          type="text"  
          id="city"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={city}  
          onChange={(e) => setCity(e.target.value)}  
        />  
      </div>  
      <div className="mb-4">  
        <label htmlFor="addressLine1" className="block mb-1">  
          Address Line 1  
        </label>  
        <input  
          type="text"  
          id="addressLine1"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={addressLine1}  
          onChange={(e) => setAddressLine1(e.target.value)}  
        />  
      </div>  
      <div className="mb-4">  
        <label htmlFor="addressLine2" className="block mb-1">  
          Address Line 2 (optional)  
        </label>  
        <input  
          type="text"  
          id="addressLine2"  
          className="w-full px-4 py-2 border border-gray-300 rounded"  
          value={addressLine2}  
          onChange={(e) => setAddressLine2(e.target.value)}  
        />  
      </div>  
      <div className="mb-4">  
        <Switch.Group>  
          <div className="flex items-center">  
            <Switch.Label className="mr-4">Residential Address</Switch.Label>  
            <Switch  
              checked={isResidential}  
              onChange={setIsResidential}  
              className={`${  
                isResidential ? 'bg-blue-600' : 'bg-gray-200'  
              } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}  
            >  
              <span  
                className={`${  
                  isResidential ? 'translate-x-6' : 'translate-x-1'  
                } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}  
              />  
            </Switch>  
          </div>  
        </Switch.Group>  
      </div>  
      <button  
        type="button"  
        className="bg-blue-500 text-white px-4 py-2 rounded"  
        onClick={() => handleSubmitAddress()}  
      >  
        Get Delivery Quote  
      </button>  
    </div>  
  );  
};  
  
export default AddressForm;  