import React, { createContext, useEffect, useState, useContext } from 'react';
import { getAffiliateData, setAffiliateData } from './indexedDB';

export const AffiliateContext = createContext({ id: undefined, tier: undefined });

export const useAffiliate = () => useContext(AffiliateContext)

export const AffiliateProvider = ({ children }) => {
  const [affiliateData, setAffiliateDataState] = useState({ id: undefined, tier: undefined });

  useEffect(() => {
    const initAffiliateData = async () => {
      const storedData = await getAffiliateData();
      if (!storedData && window.location.search) {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        const tier = params.get('tier');
        if (id && tier) {
          const data = { id, tier };
          await setAffiliateData(data);
          setAffiliateDataState(data);
        }
      } else if (storedData) {
        setAffiliateDataState(storedData);
      }
    };

    initAffiliateData();
  }, []);

  return (
    <AffiliateContext.Provider value={affiliateData}>
      {children}
    </AffiliateContext.Provider>
  );
};
