import React from 'react';  
import CategoryFilter from '../Components/CategoryFilter';  
import HuePointShop from '../Components/HuePointShop';  
  
function Points() {  
  return (  
    <div className="flex justify-center">  
      <div className="w-full max-w-7xl flex">  
        <CategoryFilter />  
        <HuePointShop />  
      </div>  
    </div>  
  );  
}  
  
export default Points; 