import React from 'react';  
import HueLogo from '../hue.jpg';  
  
const categories = ["Raffles", "Merch", "Events", "NFTs", "Tokens"];  
  
function CategoryFilter() {  
  return (  
    <div className="w-1/4 p-4 bg-gray-100">  
      <div className="text-white bg-purple-500 p-2 rounded">  
        <div className="text-lg font-bold">Available Hue Points:</div>  
        <div className="flex items-start">  
          <div className="text-lg font-bold mr-2">948</div>  
          <img src={HueLogo} alt="Your Points" className="rounded-full h-8 w-8 shadow-lg" />  
        </div>  
      </div>  
      <div className="mt-4 bg-gray-50 p-2">  
        <div className="font-bold text-lg mb-2 border-b border-gray-300 pb-2">Category</div>  
        {categories.map((category, index) => (  
          <div key={index} className="flex items-center justify-between mb-2">  
            <span>{category}</span>  
            <label className="flex items-center">  
              <input type="checkbox" className="form-checkbox" />  
            </label>  
          </div>  
        ))}  
      </div>  
    </div>  
  );  
}  
  
export default CategoryFilter;  